'use strict';

import { ActionReducerMap, MetaReducer } from '@ngrx/store';

import { environment } from '../../../environments/environment';

import * as fromApp from './app.reducer';

export interface State {
  app: fromApp.State;
}

export const reducers: ActionReducerMap<State> = {
  app: fromApp.reducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ?
  [] : // non-production
  []; // production
