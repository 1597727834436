export interface Menu {
    state: string;
    name: string;
    icon?: string;
    children?: Menu[];
}

export const MenuItems = [
    {
        state: '/starter',
        name: 'Dashboard',
        icon: 'speed'
    },
    {
        state: '/pipeline',
        name: 'Pipeline',
        icon: 'list'
    },
    {
        state: '/thirdparty',
        name: 'Third-party',
        icon: 'border_clear'
    },
    {
        state: '/tasks',
        name: 'Tasks',
        icon: 'view_list'
    },
    {
        state: '/commissions',
        name: 'Commissions',
        icon: 'emoji_symbols'
    },
    {
        state: '/links',
        name: 'Important Links',
        icon: 'link'
    }
];
