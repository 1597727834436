import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import {contact} from '../interfaces/leads.interface';
import { Constants } from '../constants';
import { Contact } from '../../model/contact';

import getUsername from '../utils/get-username';

import { User } from '../interfaces/user.interface';

@Injectable({
    providedIn: 'root'
})
export class PipelineService {

    constructor(private http: HttpClient) {}

    get options() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': Constants.API_KEY,
                'Authorization': localStorage.getItem(Constants.ELLIE_TOKEN_KEY)
            })
        };
    }

    addContact = (contact: Contact): Observable<any> => this.http.post(`${Constants.API_URL}/crm/addcontact`, contact,{
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-api-key': Constants.API_KEY,
            'Authorization': localStorage.getItem(Constants.ELLIE_TOKEN_KEY)
        })
    });

    getLeads = (user: User|string): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/prospects/${getUsername(user)}`);
    getLoans = (user: User|string): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/active/${getUsername(user)}`);

    getLoanPipelineWithRules = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/current`);
    getLoanPipelineYtdClose = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/ytd/closing`);
    getLoanPipelineMtdClose = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/mtd/closing`);

    getLoanPipelineMtdOrig = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/mtd/orig`);
    getLoanPipelineYtdReferrals = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/ytd/referrals/${JSON.parse(localStorage.getItem(Constants.USER)).user_name}`);
    getLoanPipelineYtdProducts = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/ytd/products/${JSON.parse(localStorage.getItem(Constants.USER)).user_name}`);
    getTrendRefi = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/trend/refi`);
    getTrendPurchase = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/trend/purchase`);
    getTrendOrig = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/trend/orig`);
    getTrendMonths = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/trend/months`);
    getMilestones = (): Observable<any> => this.http.get(`${Constants.API_URL}/pipeline/milestones`);
    getContactId = (loanNum: string): Observable<any> => this.http.get(`${Constants.API_URL}/crm/borrowerid/${loanNum}`);
}
