import {Constants} from '../constants';

export default function () {
    const users = Constants.USERS;
    return {
        data: users.map(user => {
            const [firstName, lastName] = user.name.split(' ');
            return {
                firstName,
                lastName,
                count: 0,
                sum: 0
            };
        }),
        totalVolume: 0,
        totalUnits: 0
    };
}
