import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './app.reducer';

export const selectAppState = createFeatureSelector<State>('app');

export const loadedLoanData = (state: State) => state.loanData;
export const loadedLoan = (state: State) => state.loadedLoan;
export const getContactId = (state: State) => state.getContactId;
export const loadedCommissions = (state: State) => state.loadedCommissions;
export const loadedLeads = (state: State) => state.loadedLeads;
export const getUserKey = (state: State) => state.userKey;
export const getViewAsUserKey = (state: State) => state.viewAsUserKey;
export const getLoginError = (state: State) => state.loginError;
export const getTokenKey = (state: State) => state.token;
export const getEllieTokenKey = (state: State) => state.ellieToken;
export const getIsMenuOpen = (state: State) => state.isMenuOpen;
export const getLoadedOriginationsMtd = (state: State) => state.loadedOriginationsMtd;
export const getLoadedClosedMtd = (state: State) => state.loadedClosedMtd;
export const getLoadedClosedYtd = (state: State) => state.loadedClosedYtd;
export const getMilestones = (state: State) => state.milestones;
export const getClosingAndOriginations = (state: State) => state.closingAndOriginations;
export const getTopReferralSources = (state: State) => state.topReferralSources;
export const getProductMixAndMetrics = (state: State) => state.productMixAndMetrics;
export const getBodyColor = (state: State) => state.bodyColor;
export const getDeviceType = (state: State) => state.deviceType;


export const selectUserKey = createSelector(selectAppState, getUserKey);
export const selectViewAsUserKey = createSelector(selectAppState, getViewAsUserKey);
export const selectLoadedLoanData = createSelector(selectAppState, loadedLoanData);
export const selectLoadedLeads = createSelector(selectAppState, loadedLeads);
export const selectLoadedLoan = createSelector(selectAppState, loadedLoan);
export const selectContactId = createSelector(selectAppState, getContactId);
export const selectLoadedCommissions = createSelector(selectAppState, loadedCommissions);
export const selectLoginError = createSelector(selectAppState, getLoginError);
export const selectTokenKey = createSelector(selectAppState, getTokenKey);
export const selectEllieTokenKey = createSelector(selectAppState, getEllieTokenKey);
export const selectIsMenuOpen = createSelector(selectAppState, getIsMenuOpen);
export const selectLoadedOriginationsMtd = createSelector(selectAppState, getLoadedOriginationsMtd);
export const selectLoadedClosedMtd = createSelector(selectAppState, getLoadedClosedMtd);
export const selectLoadedClosedYtd = createSelector(selectAppState, getLoadedClosedYtd);
export const selectMilestones = createSelector(selectAppState, getMilestones);
export const selectClosingAndOriginations = createSelector(selectAppState, getClosingAndOriginations);
export const selectTopReferralSources = createSelector(selectAppState, getTopReferralSources);
export const selectProductMixAndMetrics = createSelector(selectAppState, getProductMixAndMetrics);
export const selectBodyColor = createSelector(selectAppState, getBodyColor);
export const selectDeviceType = createSelector(selectAppState, getDeviceType);
