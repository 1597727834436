import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants} from '../../starter/constants';
import { EllieLoan } from '../interfaces/loan.interface';

@Injectable({
    providedIn: 'root'
})
export class LoanService {
    constructor(private http: HttpClient) {}

    getAllLoanDetail(guid: number): Observable<EllieLoan> {
        return combineLatest([
          this.getLoan(guid),
          this.getAttachments(guid)
        ])
          .pipe(
            map(([loan, attachments]) => ({ ...loan, attachments }))
          );
    }

    getLoan(guid: number): Observable<any> {
        return this
          .http
          .get(`https://api.elliemae.com/encompass/v1/loans/${guid}?entities=contact,borrower,coborrower,asset,employment,income,property,loan`);
    }

    getAttachments(guid: number): Observable<any> {
        return this.http.get(`https://api.elliemae.com/encompass/v1/loans/${guid}/attachments`);
    }

    getThumbnailUrlForAttachment(guid: string, attachmentId: string, page: number): Observable<any> {
        return this.http.post(`https://api.elliemae.com/encompass/v1/loans/${guid}/attachments/${attachmentId}/pages/${page}/thumbnail/url`, null);
    }

    getContactId(loanNum: string): Observable<any> {
    return this.http.get(`${Constants.API_URL}/crm/loannumber/${loanNum}`)
    }
}
