'use strict';

import { Action } from '@ngrx/store';
import { Moment } from 'moment';

import { EllieLoan, LoanData } from '../interfaces/loan.interface';
import { Commission } from '../interfaces/commission.interface';
import { AccountToken } from '../interfaces/account-token.interface';
import { EllieToken } from '../interfaces/ellie-token.interface';
import { Lead } from '../interfaces/leads.interface';
import { User } from '../interfaces/user.interface';
import { INewLead } from '../interfaces/new-lead.interface';

export enum AppActionTypes {
  AppToken = '[App] Token',
  EllieAppToken = '[App] Ellie Token',
  AppLogin = '[App] Login',
  AppLoginError = '[App] Login Error',
  AppLoginSuccess = '[App] Login Success',
  AppSetUser = '[App] Set User',
  AppSetViewAsUser = '[App] Set View As User',
  LoadLoan = '[App] Load Loan',
  LoadLoans = '[App] Load Loans',
  LoadLeads = '[App] Load Leads',
  LoadCommissions = '[App] Load Commissions',
  LoadedLoan = '[App] Loaded Loan',
  GetContactId = '[App] Contact Id',
  LoadedLoans = '[App] Loaded Loans',
  LoadedLeads = '[App] Loaded Leads',
  LoadedCommissions = '[App] Loaded Commissions',
  AppDataChange = '[App] Data Change',
  AppMenuTrigger = '[App] Menu Trigger',
  AppLoadOriginationsMtd = '[App] Load Originations - Mtd',
  AppLoadedOriginationsMtd = '[App] Loaded Originations - Mtd',
  AppLoadClosedMtd = '[App] Load Closed - Mtd',
  AppLoadedClosedMtd = '[App] Loaded Closed - Mtd',
  AppLoadClosedYtd = '[App] Load Closed - Ytd',
  AppLoadedClosedYtd = '[App] Loaded Closed - Ytd',
  AppLoadMilestones = '[App] Load Milestones',
  AppLoadedMilestones = '[App] Loaded Milestones',
  AppLoadClosingAndOriginations = '[App] Load Closing and Originations',
  AppLoadedClosingAndOriginations = '[App] Loaded Closing and Originations',
  AppLoadTopReferralSources = '[App] Load Top Referral Sources',
  AppLoadedTopReferralSources = '[App] Loaded Top Referral Sources',
  AppLoadProductMixAndMetrics = '[App] Load Product Mix And Metrics',
  AppLoadedProductMixAndMetrics = '[App] Loaded Product Mix And Metrics',
  AppBodyColor = '[App] Body Color',
  AppNewLeadCreated = '[App] New Lead Created',
  AppNewLeadCreatedSuccess = '[App] New Lead Created Success',
  AppResize = '[App] Resize'
}

export class AppNewLeadCreatedSuccess implements Action {
  readonly type = AppActionTypes.AppNewLeadCreatedSuccess;
  constructor(public data:any) {}
}

export class AppNewLeadCreated implements Action {
  readonly type = AppActionTypes.AppNewLeadCreated;
  constructor(public lead: INewLead) {}
}

export class AppBodyColor implements Action {
  readonly type = AppActionTypes.AppBodyColor;
  constructor(public bodyColor: string) {}
}

export class AppLoadProductMixAndMetrics implements Action {
  readonly type = AppActionTypes.AppLoadProductMixAndMetrics;
  constructor() {}
}

export class AppLoadedProductMixAndMetrics implements Action {
  readonly type = AppActionTypes.AppLoadedProductMixAndMetrics;
  constructor(public payload: any) {}
}

export class AppLoadTopReferralSources implements Action {
  readonly type = AppActionTypes.AppLoadTopReferralSources;
  constructor() {}
}

export class AppLoadedTopReferralSources implements Action {
  readonly type = AppActionTypes.AppLoadedTopReferralSources;
  constructor(public payload: any) {}
}


export class AppLoadClosingAndOriginations implements Action {
  readonly type = AppActionTypes.AppLoadClosingAndOriginations;
  constructor() {}
}

export class AppLoadedClosingAndOriginations implements Action {
  readonly type = AppActionTypes.AppLoadedClosingAndOriginations;
  constructor(public payload: any) {}
}

export class AppLoadMilestones implements Action {
  readonly type = AppActionTypes.AppLoadMilestones;
  constructor() {}
}

export class AppLoadedMilestones implements Action {
  readonly type = AppActionTypes.AppLoadedMilestones;
  constructor(public payload: any) {}
}

export class AppLoadClosedYtd implements Action {
  readonly type = AppActionTypes.AppLoadClosedYtd;
  constructor() {}
}

export class AppLoadedClosedYtd implements Action {
  readonly type = AppActionTypes.AppLoadedClosedYtd;
  constructor(public payload: any) {}
}


export class AppLoadClosedMtd implements Action {
  readonly type = AppActionTypes.AppLoadClosedMtd;
  constructor() {}
}

export class AppLoadedClosedMtd implements Action {
  readonly type = AppActionTypes.AppLoadedClosedMtd;
  constructor(public payload: any) {}
}

export class AppLoadOriginationsMtd implements Action {
  readonly type = AppActionTypes.AppLoadOriginationsMtd;
  constructor() {}
}

export class AppLoadedOriginationsMtd implements Action {
  readonly type = AppActionTypes.AppLoadedOriginationsMtd;
  constructor(public payload: any) {}
}

export class AppMenuTrigger implements Action {
  readonly type = AppActionTypes.AppMenuTrigger;
  constructor() {}
}

export class AppToken implements Action {
  readonly type = AppActionTypes.AppToken;
  constructor(public token: string) {}
}

export class EllieAppToken implements Action {
  readonly type = AppActionTypes.EllieAppToken;
  constructor(public token: string) {}
}


export class AppLogin implements Action {
  readonly type = AppActionTypes.AppLogin;
  constructor(public account: {user: string, pass: string}) {}
}

export class AppLoginError implements Action {
  readonly type = AppActionTypes.AppLoginError;
  constructor(public error: any) {}
}

export class AppLoginSuccess implements Action {
  readonly type = AppActionTypes.AppLoginSuccess;
  constructor(public payload: {token: AccountToken, ellieToken: EllieToken}) {}
}

export class AppSetUser implements Action {
  readonly type = AppActionTypes.AppSetUser;
  constructor(public payload: string) {}
}

export class AppSetViewAsUser implements Action {
  readonly type = AppActionTypes.AppSetViewAsUser;
  constructor(public payload: string) {}
}

export class LoadLoan implements Action {
  readonly type = AppActionTypes.LoadLoan;
  constructor(public id: string) {}
}

export class LoadLoans implements Action {
  readonly type = AppActionTypes.LoadLoans;
  constructor(public payload: {user?: User|string}) {}
}

export class LoadLeads implements Action {
  readonly type = AppActionTypes.LoadLeads;
  constructor(public payload: {user?: User|string}) {}
}

export class LoadCommissions implements Action {
  readonly type = AppActionTypes.LoadCommissions;
  constructor(public payload?: {date: Moment, user?: User}) {}
}

export class LoadedLoan implements Action {
  readonly type = AppActionTypes.LoadedLoan;
  constructor(public payload: EllieLoan) {}
}

export class GetContactId implements Action {
  readonly type = AppActionTypes.GetContactId;
  constructor(public payload: {loanNum?: string}) {}
}

export class LoadedLoans implements Action {
  readonly type = AppActionTypes.LoadedLoans;
  constructor(public payload: LoanData) {}
}

export class LoadedLeads implements Action {
  readonly type = AppActionTypes.LoadedLeads;
  constructor(public payload: Lead[]) {}
}

export class LoadedCommissions implements Action {
  readonly type = AppActionTypes.LoadedCommissions;
  constructor(public payload: Commission[]) {}
}

export class AppDataChange implements Action {
  readonly type = AppActionTypes.AppDataChange;
  constructor(public payload: {date?: Moment, user?: User}) {}
}

export class AppResize implements Action {
  readonly type = AppActionTypes.AppResize;
  constructor(public device: string) {}
}

export type AppActions =
  | AppResize
  | AppNewLeadCreatedSuccess
  | AppNewLeadCreated
  | AppBodyColor
  | AppLoadProductMixAndMetrics
  | AppLoadedProductMixAndMetrics
  | AppLoadTopReferralSources
  | AppLoadedTopReferralSources
  | AppLoadClosingAndOriginations
  | AppLoadedClosingAndOriginations
  | AppLoadMilestones
  | AppLoadedMilestones
  | AppLoadClosedYtd
  | AppLoadedClosedYtd
  | AppLoadClosedMtd
  | AppLoadedClosedMtd
  | AppLoadOriginationsMtd
  | AppLoadedOriginationsMtd
  | AppMenuTrigger
  | AppToken
  | EllieAppToken
  | AppLogin
  | AppLoginError
  | AppLoginSuccess
  | AppSetUser
  | AppSetViewAsUser
  | LoadLoan
  | LoadLoans
  | LoadLeads
  | LoadCommissions
  | LoadedLoan
  | GetContactId
  | LoadedLoans
  | LoadedCommissions
  | LoadedLeads;
