import { environment } from '../../environments/environment';

import { User } from './interfaces/user.interface';

export class Constants {
    public static get API_URL(): string {
        return environment.api_url;
    }

    public static get ELLIE_URL(): string {
        return environment.ellie_url;
    }

    public static get API_KEY(): string {
        return environment.api_key;
    }

    public static get USER(): string {
        return 'user';
    }

    public static get USER_KEY(): string {
        return 'user-token';
    }

    public static get ELLIE_TOKEN_KEY(): string {
        return 'ellie-token';
    }

    public static get USERS(): User[] {
        return [
            {
                'name': 'Ben Seibert',
                'username': 'bseibertvlm'
            },
            {
                'name': 'Pete Cullen',
                'username': 'pcullenvlm'
            },
            {
                'name': 'Karen Semerad',
                'username': 'ksemeradvlm'
            },
            {
                'name': 'Courtney Short',
                'username': 'cshort'
            },
            {
                'name': 'Nicole Doyle',
                'username': 'adoyle'
            },
            {
                'name': 'Diana Blanks',
                'username': 'dblanks'
            },
            {
                'name': 'Elizabeth Larrazabal',
                'username': 'clarrazabal'
            },
            {
                'name': 'Eileen Pantich',
                'username': 'epantich'
            },
            {
                'name': 'Jeremy Kingsbury',
                'username': 'jkingsbury'
            },
            {
                'name': 'Fatima Rahimi',
                'username': 'frahimi'
            },
            {
                'name': 'Libbey Boyd',
                'username': 'lboyd'
            },
            {
                'name': 'Patrick Gardner',
                'username': 'igardner'
            },
            {
                'name': 'Jay Curley',
                'username': 'jcurley'
            },
            {
                'name': 'Zak Rhodes',
                'username': 'zrhodesvlm'
            },
            {
                'name': 'Jacque Sommer',
                'username': 'jsommer'
            },
            {
                'name': 'Shannon Leydig',
                'username': 'sleydig'
            },
            {
                'name': 'Nathan Burch',
                'username': 'nburch'
            },
            {
                'name': 'Danny Ott',
                'username': 'dottvlm'
            },
            {
                'name': 'Eric Hopkins',
                'username': 'ehopkins'
            },
            {
                'name': 'Greg Kingsbury',
                'username': 'gkingsbury'
            },
            {
                'name': 'Carlos Larrazabal',
                'username': 'clarrazabal'
            },
            {
                'name': 'Kevin Finnegan',
                'username': 'kfinneganvlm'
            },
            {
                'name': 'Tina Longo',
                'username': 'tlongo'
            },
            {
                'name': 'Michael McClellan',
                'username': 'mmclellan'
            },
            {
                'name': 'Nate Den Herder',
                'username': 'ndenherder'
            }
        ];
    }
}
