import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Moment } from 'moment';
import * as moment from 'moment';
import { get } from 'lodash';
import 'rxjs/add/operator/map';

import { User } from '../interfaces/user.interface';

import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class CommissionsService {
  
  toggleMenu:Subject<boolean> = new Subject();
  constructor(private http: HttpClient) {}

  getCommissions(date?: Moment, user?: User): Observable<any> {
    let dateString = moment().format('YYYY/MM');
    let currentUser = get(JSON.parse(localStorage.getItem(Constants.USER)) || {}, 'user_name');
    if (user) {
      currentUser = user.username;
    }
    if (date) {
      dateString = date.format('YYYY/MM');
    }
    return this
      .http
      .get(`${Constants.API_URL}/pipeline/commissions/${currentUser}/${dateString}`);
  }

  setToggleMenu(val) {
    this.toggleMenu.next(val)
  }

  getToggleMenu(){
    return this.toggleMenu;
  }
}
