import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { WINDOW } from '@vellum/factories';

import { selectBodyColor, selectViewAsUserKey } from './starter/store/app.selectors';
import { State } from './starter/store/app.reducer';

import 'hammerjs';
import { AppDataChange, AppResize } from './starter/store/app.actions';
import { Desktop, Phone, Tablet } from './constants/DeviceType';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  viewAsUserKey$ = this.store.pipe(select(selectViewAsUserKey));

  constructor(
    private store: Store<State>,
    private element: ElementRef,
    @Inject(WINDOW) private window: Window
  ) {
    this.resize();
  }

  ngOnInit() {
      this
        .store
        .pipe(select(selectBodyColor))
        .subscribe(bodyColor => {
            document
              .body
              .style
              .backgroundColor = `${bodyColor}`;
        });

    this
      .viewAsUserKey$
      .subscribe(user => this.store.dispatch(new AppDataChange({user: {username: user}})));

    this.window.addEventListener('resize', this.resize, false);
  }

  resize = () => {
    const {innerWidth} = this.window;
    const {nativeElement} = this.element as ({nativeElement: HTMLElement});
    if (nativeElement) {
      let device = Phone;
      if (innerWidth > 768) {
        device = Tablet;
      }
      if (innerWidth > 1024) {
        device = Desktop;
      }
      this.store.dispatch(new AppResize(device));
      nativeElement.classList.remove(Phone, Tablet, Desktop);
      nativeElement.classList.add(device);
    }
  }
}
