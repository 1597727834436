// modules
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// directives
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './directives/accordion';

// components
import { SpinnerComponent } from './components/spinner/spinner.component';

// pipes
import { PhonePipe } from './pipes/phone.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent
   ],
  providers: [
    // pipes
    PhonePipe
  ]
})
export class SharedModule {}
