import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

import { Constants } from '../constants';

import { WINDOW } from '@vellum/factories';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic c2R3Z2IyaTpoNWJleFl3dyp0bHVha3hHXndzZTVORmx1NWlTdmVnTlRWbTh2Ulc2ciFwVFdpTWVhTGZhZnRuVlpzY1RwVTVW'
    })
};

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private userKey = 'ellie-token';

    constructor(
        private http: HttpClient,
        private userIdle: UserIdleService,
        private router: Router,
        @Inject(WINDOW) private window: Window
    ) {}

    getHost(): string {
        return `${this.window.location.protocol}//${this.window.location.hostname}`;
    }

    loginEllie(code: string): Observable<any> {
        const body = `grant_type=authorization_code&redirect_uri=${this.getHost()}/handleLoginRedirect&code=${code}`;
        return this.http.post('https://api.elliemae.com/oauth2/v1/token', body, httpOptions);
    }

    readEllieToken(token: string): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': Constants.API_KEY
            })
        };

        const body = { 'token': token };
        return this.http.post(`${Constants.API_URL}/auth/ellie/user`, JSON.stringify(body), options);
    }

    getApiToken(user: string, pass: string): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': Constants.API_KEY
            })
        };

        const body = { 'username': user, 'password': pass };
        return this.http.post(
            `${Constants.API_URL}/auth/ellie`,
            JSON.stringify(body),
            options);
    }

    logout() {
        localStorage.removeItem(this.userKey);
        this.router.navigate(['/login']).then();
    }
}
