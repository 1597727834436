import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

// core modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { UserIdleModule } from 'angular-user-idle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';

// our modules
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';

// components
import { AppComponent } from './app.component';

// services
import { PipelineService } from './starter/services/pipeline.service';
import { LoginService } from './starter/services/login.service';

// factories
import { DOCUMENT_PROVIDER } from './starter/factories/document.factory';
import { WINDOW_PROVIDER } from './starter/factories/window.factory';

// interceptors
import { AuthInterceptor, HttpHeadersInterceptor } from '@vellum/shared';

// store
import { metaReducers, reducers } from './starter/store';
import { AppEffects } from './starter/store/app.effects';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        DragDropModule,
        UserIdleModule.forRoot({idle: 20, timeout: 10, ping: 10}),
        StoreModule.forRoot(reducers, {metaReducers}),
        EffectsModule.forRoot([]),
        EffectsModule.forFeature([AppEffects]),
    ],
    providers: [
        LoginService,
        WINDOW_PROVIDER,
        DOCUMENT_PROVIDER,
        PipelineService,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpHeadersInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
