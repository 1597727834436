'use strict';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Moment } from 'moment';
import { forkJoin, of } from 'rxjs';

import {
  AppActionTypes,
  AppLoadedClosedMtd,
  AppLoadedClosedYtd,
  AppLoadedClosingAndOriginations,
  AppLoadedMilestones,
  AppLoadedOriginationsMtd,
  AppLoadedProductMixAndMetrics,
  AppLoadedTopReferralSources,
  AppLoginError,
  AppLoginSuccess,
  AppNewLeadCreatedSuccess,
  LoadCommissions,
  LoadedCommissions,
  LoadedLeads,
  GetContactId,
  LoadedLoan,
  LoadedLoans,
  LoadLeads,
  LoadLoans
} from './app.actions';

import { State } from './app.reducer';

import { PipelineService } from '../services/pipeline.service';
import { CommissionsService } from '../services/commissions.service';
import { LoginService } from '../services/login.service';
import { LoanService } from '../services/loan.service';

import getEmptyTable from '../utils/get-empty-table';

import { AccountToken } from '../interfaces/account-token.interface';
import { EllieToken } from '../interfaces/ellie-token.interface';
import { User } from '../interfaces/user.interface';

import { Constants } from '../constants';

@Injectable()
export class AppEffects {
  @Effect()
  login$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLogin),
      exhaustMap((payload: { account: {user: string, pass: string}}) =>
        this
          .login
          .getApiToken(payload.account.user, payload.account.pass)
          .pipe(
            take(1),
            mergeMap((token: AccountToken) => {
              if (token.error) {
                return of(new AppLoginError(token.error));
              }
              return this
                .login
                .readEllieToken(token.access_token)
                .pipe(
                  map(ellieToken => new AppLoginSuccess({token, ellieToken}))
                );
            })
          )
      )
    );

  @Effect({dispatch: false})
  loginSuccess$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoginSuccess),
      tap((action: {payload: {token: AccountToken, ellieToken: EllieToken}}) => {
        localStorage.setItem(Constants.ELLIE_TOKEN_KEY, action.payload.ellieToken.bearer_token);
        localStorage.setItem(Constants.USER_KEY, action.payload.token.access_token);
        localStorage.setItem(Constants.USER, JSON.stringify(action.payload.ellieToken));
        return this.router.navigate(['']);
      })
    );

  @Effect()
  loadLoans$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.LoadLoans),
      exhaustMap((action: { payload: {user?: User|string}}) =>
        this
          .pipeline
          .getLoans(action.payload.user)
          .pipe(
            take(1),
            switchMap(loans => [
              new LoadedLoans({items: loans})
            ])
          )
      )
    );

  @Effect()
  loadLeads$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.LoadLeads),
      exhaustMap((action: { payload: {user?: User|string}}) =>
        this
          .pipeline
          .getLeads(action.payload.user)
          .pipe(
            take(1),
            switchMap(leads => [
              new LoadedLeads(leads)
            ])
          )
      )
    );
    @Effect()
    contactId$ = this
      .actions$
      .pipe(
        ofType(AppActionTypes.GetContactId),
        exhaustMap((action: { payload: {loanNum?: string}}) =>
          this
            .pipeline
            .getContactId(action.payload.loanNum)
            .pipe(
              take(1),
              switchMap(contactId => [
                new GetContactId(contactId)
              ])
            )
        )
      );
  @Effect()
  loadLoan$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.LoadLoan),
      exhaustMap((action: {id: number}) =>
        this
          .loans
          .getAllLoanDetail(action.id)
          .pipe(
            take(1),
            switchMap(loan => [
              new LoadedLoan(loan)
            ])
          )
      )
    );

  @Effect()
  loadCommissions$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.LoadCommissions),
      exhaustMap((action: {payload?: { date: Moment, user: User }}) =>
        this
          .commissions
          .getCommissions(action.payload?.date, action.payload?.user)
          .pipe(
            take(1),
            switchMap(commissions => [
              new LoadedCommissions(commissions)
            ])
          )
      )
    );

  @Effect()
  dataChange$ = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppDataChange),
      switchMap((action: {payload: { date: Moment, user: User }}) => {
        return [
          new LoadCommissions({date: action.payload.date, user: action.payload.user}),
          new LoadLeads({user: action.payload.user}),
          new LoadLoans({user: action.payload.user})
        ];
      })
    );

  @Effect()
  appLoadOriginationsMtd = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadOriginationsMtd),
      exhaustMap(() =>
        this
          .pipeline
          .getLoanPipelineMtdOrig()
          .pipe(
            take(1),
            map(data => {
              data = data || [];
              if (!data.length) {
                return getEmptyTable();
              }
              const totalVolume = data.map(item => item.sum).reduce((a, b) => a + b);
              const totalUnits = data.map(item => item.count).reduce((a, b) => a + b);
              return {
                data,
                totalVolume,
                totalUnits
              };
            }),
            switchMap(mappedData => [
              new AppLoadedOriginationsMtd(mappedData)
            ])
          )
      )
    );

  @Effect()
  appLoadClosedMtd = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadClosedMtd),
      exhaustMap(() =>
        this
          .pipeline
          .getLoanPipelineMtdClose()
          .pipe(
            take(1),
            map(data => {
              data = data || [];
              if (!data.length) {
                return getEmptyTable();
              }
              const totalVolume = data.map(item => item.sum).reduce((a, b) => a + b);
              const totalUnits = data.map(item => item.count).reduce((a, b) => a + b);
              return {
                data,
                totalVolume,
                totalUnits
              };
            }),
            switchMap(mappedData => [
              new AppLoadedClosedMtd(mappedData)
            ])
          )
      )
    );

  @Effect()
  appLoadClosedYtd = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadClosedYtd),
      exhaustMap(() =>
        this
          .pipeline
          .getLoanPipelineYtdClose()
          .pipe(
            take(1),
            map(data => {
              data = data || [];
              if (!data.length) {
                return getEmptyTable();
              }
              const totalVolume = data.map(item => item.sum).reduce((a, b) => a + b);
              const totalUnits = data.map(item => item.count).reduce((a, b) => a + b);
              return {
                data,
                totalVolume,
                totalUnits
              };
            }),
            switchMap(mappedData => [
              new AppLoadedClosedYtd(mappedData)
            ])
          )
      )
    );

  @Effect()
  appLoadMilestones = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadMilestones),
      exhaustMap(() =>
        this
          .pipeline
          .getMilestones()
          .pipe(
            take(1),
            switchMap(data => [
              new AppLoadedMilestones(data)
            ])
          )
      )
    );

  @Effect()
  appLoadClosingAndOriginations = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadClosingAndOriginations),
      switchMap(() => {
        return forkJoin([
          this.pipeline.getTrendOrig(),
          this.pipeline.getTrendRefi(),
          this.pipeline.getTrendPurchase()
        ]);
      }),
      map(data => ({ originations: data[0], refinance: data[1], purchase: data[2] })),
      switchMap(closingAndOriginations => [new AppLoadedClosingAndOriginations(closingAndOriginations)])
    );

  @Effect()
  appLoadTopReferralSources = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadTopReferralSources),
      exhaustMap(() =>
        this
          .pipeline
          .getLoanPipelineYtdReferrals()
          .pipe(
            take(1),
            switchMap(data => [
              new AppLoadedTopReferralSources(data)
            ])
          )
      )
    );

  @Effect()
  appLoadProductMixAndMetrics = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppLoadProductMixAndMetrics),
      exhaustMap(() =>
        this
          .pipeline
          .getLoanPipelineYtdProducts()
          .pipe(
            take(1),
            switchMap(data => [
              new AppLoadedProductMixAndMetrics(data)
            ])
          )
      )
    );


  @Effect()
  appNewLeadCreated = this
    .actions$
    .pipe(
      ofType(AppActionTypes.AppNewLeadCreated),
      exhaustMap(lead => 
        this
          .pipeline
          .addContact(lead['lead'])
          .pipe(
            take(1),
            switchMap(data => [
              new AppNewLeadCreatedSuccess(data)
            ])
          )
      ),
      catchError((err) => {
        return of(new AppNewLeadCreatedSuccess(err));
      })
    );

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private router: Router,
    private pipeline: PipelineService,
    private commissions: CommissionsService,
    private login: LoginService,
    private loans: LoanService
  ) {}
}
