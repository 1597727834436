'use strict';

import { AppActions, AppActionTypes } from './app.actions';

import { EllieLoan, LoanData } from '../interfaces/loan.interface';
import { Commission } from '../interfaces/commission.interface';
import { Lead } from '../interfaces/leads.interface';

import { BaseComponent } from '../components/base.component';

export interface State {
  deviceType: string;
  userKey: string;
  viewAsUserKey: string;
  loanData: LoanData;
  loadedLoan: EllieLoan;
  getContactId:any;
  loadedCommissions: Commission[];
  loadedLeads: Lead[];
  loginError: boolean;
  token: string;
  ellieToken: string;
  isMenuOpen: boolean;
  loadedOriginationsMtd: any;
  loadedClosedMtd: any;
  loadedClosedYtd: any;
  milestones: any;
  closingAndOriginations: any;
  topReferralSources: any;
  productMixAndMetrics: any;
  bodyColor: string;
}

export const initialState: State = {
  deviceType: null,
  userKey: null,
  viewAsUserKey: null,
  loanData: null,
  loadedLoan: null,
  loadedCommissions: null,
  loadedLeads: null,
  loginError: false,
  token: null,
  ellieToken: null,
  isMenuOpen: false,
  loadedOriginationsMtd: null,
  loadedClosedMtd: null,
  loadedClosedYtd: null,
  milestones: null,
  getContactId: null,
  closingAndOriginations: null,
  topReferralSources: null,
  productMixAndMetrics: null,
  bodyColor: BaseComponent.defaultBodyColor
};

export function reducer(state = initialState, action: AppActions): State {
  switch (action.type) {
    case AppActionTypes.AppResize:
      return { ...state, deviceType: action.device };
    case AppActionTypes.AppBodyColor:
      return { ...state, bodyColor: action.bodyColor };
    case AppActionTypes.AppLoadedProductMixAndMetrics:
      return { ...state, productMixAndMetrics: action.payload };
    case AppActionTypes.AppLoadedTopReferralSources:
      return { ...state, topReferralSources: action.payload };
    case AppActionTypes.AppLoadedClosingAndOriginations:
      return { ...state, closingAndOriginations: action.payload };
    case AppActionTypes.AppLoadedMilestones:
      return { ...state, milestones: action.payload };
    case AppActionTypes.AppLoadedClosedYtd:
      return { ...state, loadedClosedYtd: action.payload };
    case AppActionTypes.AppLoadedClosedMtd:
      return { ...state, loadedClosedMtd: action.payload };
    case AppActionTypes.AppLoadedOriginationsMtd:
      return { ...state, loadedOriginationsMtd: action.payload };
    case AppActionTypes.AppMenuTrigger:
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case AppActionTypes.AppToken:
      return { ...state, token: action.token };
    case AppActionTypes.EllieAppToken:
      return { ...state, ellieToken: action.token };
    case AppActionTypes.AppLogin:
      return { ...state, loginError: false };
    case AppActionTypes.LoadedLoans:
      return { ...state, loanData: action.payload };
    case AppActionTypes.LoadedLoan:
      return { ...state, loadedLoan: action.payload };
    case AppActionTypes.LoadedCommissions:
      return { ...state, loadedCommissions: action.payload };
    case AppActionTypes.LoadedLeads:
      return { ...state, loadedLeads: action.payload };
    case AppActionTypes.AppSetUser:
      return { ...state, userKey: action.payload };
    case AppActionTypes.AppSetViewAsUser:
      return { ...state, viewAsUserKey: action.payload };
    case AppActionTypes.AppLoginError:
      return { ...state, loginError: true };
      case AppActionTypes.GetContactId:
      return { ...state, getContactId: action.payload };
    default:
      return state;
  }
}
