import 'rxjs/add/operator/do';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, flatMap } from 'rxjs/operators';

import { Constants } from '../constants';

import { selectEllieTokenKey, selectTokenKey } from '../store/app.selectors';

@Injectable()
export class HttpHeadersInterceptor implements HttpInterceptor {
  blacklist: string[] = [
    'auth/ellie',
    'auth/ellie/user'
  ];

  constructor(private store: Store<any>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const blacklistItem = this.blacklist.find(item => `${Constants.API_URL}/${item}` === request.url);
    let isEllieUrl = false;
    if (request.url.includes(Constants.ELLIE_URL)) {
      isEllieUrl = true;
    }
    if ((request.url.includes(Constants.API_URL) || isEllieUrl) && typeof blacklistItem === 'undefined') {
      return this
        .store
        .select(selectTokenKey)
        .pipe(
          first(),
          flatMap(tokenKey => {
            const clonedRequest = request.clone({ ...(!isEllieUrl ? this.userOptions(tokenKey) : this.ellieOptions(tokenKey)) });
            return next.handle(clonedRequest);
          })
        );
    }
    return next.handle(request);
  }

  ellieOptions(key: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${key}`
      })
    };
  }

  userOptions(key: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': Constants.API_KEY,
        'Authorization': key
      })
    };
  }
}
