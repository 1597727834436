import { trigger, state, style, transition, animate } from '@angular/animations';

export const onSideNavAnimate = trigger('onSideNavAnimate', [
  state('close',
    style({
      'width': '78px'
    })
  ),
  state('open',
    style({
      'width': '252px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in'))
]);

export const onMainContentChange = trigger('onMainContentChange', [
  state('close',
    style({
      'margin-left': '82px'
    })
  ),
  state('open',
    style({
      'margin-left': '262px'
    })
  ),
  transition('close => open', animate('250ms ease-in')),
  transition('open => close', animate('250ms ease-in'))
]);


