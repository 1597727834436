import { FactoryProvider, InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Document>('window');

const documentProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

export const WINDOW_PROVIDER = [documentProvider];
