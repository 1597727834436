import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '../store/app.reducer';
import { AppBodyColor } from '../store/app.actions';

@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnInit, OnDestroy {
  public static readonly defaultBodyColor = '#E7EEF2';

  bodyColor = '#E7EEF2';

  constructor(public store: Store<State>) {}

  ngOnInit() {
    this.store.dispatch(new AppBodyColor(this.bodyColor));
  }

  ngOnDestroy() {
    this.store.dispatch(new AppBodyColor(BaseComponent.defaultBodyColor));
  }
}
